<template>
  <div class="container">
    <app-bar
      title="Flash Sale"
      previousLink="/" />

    <div class="row">
      <ul class="nav nav-tabs flex-nowrap overflow-scroll pb-0" role="tablist">
        <li class="nav-item" role="presentation" v-for="(flashSale, i) in flashSales" :key="flashSale.nama_group">
          <button class="nav-link" :class="{ 'active':listActive[i] }" data-bs-toggle="tab" :data-bs-target="`#${flashSale.nama_group.split(' ').join('_')}`" type="button" role="tab">
            <span class="time fw-700">{{ countDown[i] }}</span><br>
            <label class="fw-400">
              {{ statusMessage(flashSale.jam_selesai, parseInt(flashSale.aktif)) }}
            </label>
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <Loading v-if="loading.flashSale" />
        <Empty v-else-if="!flashSales || flashSales.length === 0" :message="'Saat ini belum ada Flash Sale untuk Anda.'"/>
        <div v-for="(flashSale, i) in flashSales" :key="flashSale.nama_group" class="tab-pane fade" :class="{ 'show active':listActive[i] }" :id="flashSale.nama_group.split(' ').join('_')" role="tabpanel">
          <div class="row">
            <div v-for="datum in flashSale.detail" :key="datum" class="col-12 col-md-6 pt-2 pb-2">
              <div class="card border-0 shadow">
                <div class="card-body p-0">
                  <div class="d-flex flex-column">
                    <div class="border-radius-10">
                      <img class="icon-vouchers" :src="datum.image">
                    </div>
                    <div class="px-3 py-2">
                      <div class="row">
                        <div class="d-flex align-items-center justify-content-between">
                          <span class="fs-5">Rp. {{ parseInt(datum.harga_netto).toLocaleString('id-ID') }}</span>
                          <span class="text-muted small">{{ datum.terjual }} Terjual</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="d-flex align-items-center">
                          <span class="text-muted text-decoration-line-through small">Rp. {{ parseInt(datum.harga_bruto).toLocaleString('id-ID') }}</span>
                        </div>
                      </div>
                      <div class="row px-2 mt-2">
                        <button class="btn btn-warning text-white px-4 ms-auto" :disabled="!parseInt(flashSale.aktif) || loadingBuy" @click="buy(datum.id_flashsale, datum.jenis)">
                          KLAIM
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal-flash-sale :selectedFlashSaleId="selectedFlashSaleId"/>
</template>

<script>
import { getFlashSale, flashSales, loading, getDetailFlashSale, buyFlashSale } from '@/core/flashSale'
import AppBar from '@/components/AppBar.vue'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { nextTick, onMounted } from '@vue/runtime-core'
import { title } from '@/core/page'
import { ref } from 'vue'
import moment from 'moment'
import { selectedBranch } from '@/core/subDivisions'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import ModalFlashSale from '@/components/home/flashSale/ModalFlashSale.vue'
import { token } from '@/core/auth'

export default {
  components: {
    AppBar,
    Loading,
    Empty,
    ModalFlashSale
  },
  setup () {
    const listActive = ref([])
    const router = useRouter()
    const countDown = ref([])
    const selectedFlashSaleId = ref(null)
    const loadingBuy = ref(false)

    const addLeadingZero = (number) => {
      if (number < 10) {
        return `0${number}`
      }
      return `${number}`
    }

    const countDownTimer = (needCountDown = false) => {
      setTimeout(() => {
        for (let i = 0; i < flashSales.value.length; i++) {
          const duration = moment.duration(moment(flashSales.value[i].jam_selesai, 'HH:mm:ss').diff(moment()))
          if (duration.asSeconds() > 0) {
            countDown.value[i] = `${addLeadingZero(duration.hours())} : ${addLeadingZero(duration.minutes())} : ${addLeadingZero(duration.seconds())}`
            needCountDown = true
          }
        }
        if (needCountDown === true) {
          countDownTimer()
        }
      }, 1000)
    }

    const setActive = () => {
      if (flashSales.value) {
        let hasActive = 0
        for (let i = 0; i < flashSales.value.length; i++) {
          if (parseInt(flashSales.value[i].aktif)) {
            hasActive = 1
            listActive.value.push(1)
          } else {
            listActive.value.push(0)
          }
        }

        if (!hasActive) {
          listActive.value[0] = 1
        }
      }
    }

    const statusMessage = (jam, aktif) => {
      const d = new Date()
      const current = new Date()
      const [hours, minutes, seconds] = jam.split(':')

      d.setHours(+hours)
      d.setMinutes(minutes)
      d.setSeconds(seconds)

      if (aktif) {
        return 'Sedang Berjalan'
      } else if (current > d) {
        return 'Telah Berakhir'
      } else {
        return 'Akan Datang'
      }
    }

    const buy = async (id, jenis) => {
      loadingBuy.value = true

      if (!token.value) {
        return router.push({
          name: 'Login'
        })
      }

      if (jenis === 'PAKET') {
        if (!selectedBranch.value) {
          return router.push({
            name: 'SelectBranch',
            query: {
              redirectTo: '/'
            }
          })
        }
      } else if (jenis === 'KODEPXFREE') {
        const flashSale = await getDetailFlashSale(id)
        if (flashSale?.data[0]?.id_flashsale) {
          selectedFlashSaleId.value = flashSale?.data[0]?.id_flashsale
          const modal = Modal.getOrCreateInstance(
            document.querySelector('#modal-flash-sale')
          )
          modal.show()
        }
        loadingBuy.value = false
        return
      }
      buyFlashSale(id)
      loadingBuy.value = false
    }

    onMounted(async () => {
      await getFlashSale()
      setActive()
      nextTick(() => {
        title.value = 'Flash Sale'
        countDownTimer()
      })
    })

    return {
      loading,
      flashSales,
      statusMessage,
      listActive,
      buy,
      countDown,
      selectedFlashSaleId,
      loadingBuy
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

.nav-tabs {
  border: none;
}

.nav-tabs .nav-item {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item .nav-link .time {
  font-size: 14px;
  line-height: 24px;
  color: #A0AEC0;
}

.nav-tabs .nav-item .nav-link label {
  font-size: 10px;
  line-height: 10px;
  color: #A0AEC0;
}

.nav-tabs .nav-link.active .time,
.nav-tabs .nav-link.active label {
  color: $warning;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none;
  border-bottom: 3px solid $warning;
}

.icon-vouchers {
  width: 100%;
  height: auto;
}

.card {
  border-radius: 14px;
}

.card img {
  // height: 136px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.title-content-package {
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
}

.title-sold-item {
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 4px;
}

.title-discount {
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 8px;
}
</style>
